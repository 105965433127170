<template>
    <!-- Navbar -->
    <div :id="dynamicId">
        <nav class="navbar navbar-expand-lg navbar-dark">
            <!-- Container wrapper -->
            <div class="container-fluid align-items-center py-3 py-lg-4 px-3 px-lg-5 position-relative">
                <!-- Navbar brand -->
                <a class="navbar-brand order-0 order-lg-0 me-0 py-0" style="z-index: 40;"
                    @click="if ($route.name != 'ManageView') $router.push({ name: 'HomeView' }); else $router.push({ name: 'ManageView' });">
                    <div class="logo">
                        <img src="@/assets/img/logo2.svg">
                    </div>
                </a>
                <!-- Toggle button -->
                <button v-if="$route.name != 'ManageView'"
                    class=" d-flex d-lg-none align-items-center navbar-toggler ps-3 pe-0 py-0 order-2 text-white"
                    type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                    style="z-index: 30;">
                    <span class="pe-2" style="font-size: 0.8rem;  line-height: initial;">MENU</span>
                    <i class="fas fa-bars"></i>
                </button>
                <!-- Collapsible wrapper -->
                <div class="collapse navbar-collapse flex-column flex-lg-row" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-lg-auto align-items-lg-center mb-0" v-if="$route.name != 'ManageView'">
                        <li class="nav-item p-3 py-lg-0 px-lg-3">
                            <a class="nav-link"
                                @click="$router.push({ name: 'AboutView' }); this.$parent.key++"><span>WHO
                                    WE ARE</span></a>
                        </li>
                        <li class="nav-item p-3 py-lg-0 px-lg-3">
                            <a class="nav-link"
                                @click="$router.push({ name: 'ProductListView' }); this.$parent.key++"><span>MODELS</span></a>
                        </li>
                        <li class="nav-item p-3 py-lg-0 px-lg-3">
                            <a class="nav-link"
                                @click="$router.push({ name: 'NewsListView' }); this.$parent.key++"><span>MEDIA</span></a>
                        </li>
                        <li class="nav-item p-3 py-lg-0 px-lg-3">
                            <a class="nav-link"
                                @click="$router.push({ name: 'ContactView' }); this.$parent.key++"><span>CONTACT
                                    US</span></a>
                        </li>
                    </ul>
                    <ul class="navbar-nav flex-row mt-5 mt-lg-0" v-if="$route.name != 'ManageView'">
                        <!-- <li class="nav-item px-3 px-lg-0 ms-lg-5" v-if="!this.$cookies.get('token')">
                            <a class="nav-link" @click="$router.push({ name: 'AdminLoginView' }); this.$parent.key++"><span
                                    class="social-btn"><i class="fas fa-sign-in-alt"></i></span></a>
                        </li> -->
                        <!-- @click="$router.push({ name: 'DealerLoginView' });this.$parent.key++" -->
                        <li class="nav-item px-3 px-lg-0 ms-lg-5" v-if="!this.$cookies.get('token')">
                            <a class="nav-link"
                                @click="$router.push({ name: 'DealerLoginView' }); this.$parent.key++"><span
                                    class="social-btn"><i class="fas fa-sign-in-alt"></i></span></a>
                        </li>
                        <!-- <li class="nav-item px-3 px-lg-0 ms-lg-5" v-if="this.$cookies.get('token')">
                            <a class="nav-link" @click="$router.push({ name: 'ManageView' }); this.$parent.key++"><span
                                    class="social-btn"><i class="fa-solid fa-user"></i></span></a>
                        </li> -->
                        <li class="nav-item px-3 px-lg-0 ms-lg-5" v-if="this.$cookies.get('token')">
                            <a class="nav-link" @click="$router.push({ name: 'AccountView' }); this.$parent.key++"><span
                                    class="social-btn"><i class="fa-solid fa-user"></i></span></a>
                        </li>

                        <li class="nav-item px-3 px-lg-0 ms-lg-4">
                            <a class="nav-link" @click="$router.push({ name: 'FindusView' }); this.$parent.key++"><span
                                    class="social-btn"><i class="fas fa-map-marker-alt"></i></span></a>
                        </li>
                    </ul>
                    <ul class="navbar-nav d-lg-none flex-row mb-0 small">
                        <li class="nav-item px-2">
                            <a class="nav-link" href="https://www.youtube.com/@AccessMotorGlobal"><i
                                    class="fab fa-youtube"></i>Youtube</a>
                        </li>
                        <li class="nav-item px-2">
                            <a class="nav-link" href="https://www.linkedin.com/company/access-motor-co/"><i
                                    class="fab fa-linkedin"></i>Linkedin</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav d-lg-none flex-row justify-content-between my-3 small">
                        <li class="nav-item px-2">
                            <a class="nav-link" @click="$router.push({ name: 'TermsView' });">Terms & Conditions</a>
                        </li>
                        <li class="nav-item px-2">
                            <a class="nav-link" @click="$router.push({ name: 'PrivacyView' });">Privacy Policy</a>
                        </li>
                    </ul>
                    <div class="small text-center text-white-50 d-block d-lg-none p-4">
                        <span class="me-3 small">TEL +886 6-5955775</span>
                        <span class="me-3 small">FAX +886 6-5953393</span>
                        <span class="d-block small">No.288, Sec.1, Guanxin Rd., Guanmiao Dist., Tainan City 718, Taiwan
                            (R.O.C.)</span>
                    </div>
                </div>
            </div>
            <!-- Container wrapper -->
        </nav>
    </div>
    <!-- Navbar -->
</template>

<script>
export default {
    data() {
        return {
            dynamicId: 'nav',
        }
    },
    watch: {
        '$route.name': {
            handler() {
                this.updateDynamicId()
            },
            immediate: true,
        },
    },
    methods: {
        updateDynamicId() {
            const dealerNames = ["AccountView", "WorkpieceListView", "PartListView", "OrderView", "CartView", "SupportView", "CheckOutView", "OrderUploadCheckOutView", "ManageView"];
            this.dynamicId = dealerNames.includes(this.$route.name) ? 'dealernav' : 'nav';
        },
    },

}
</script>

<style>
#dealernav .navbar .logo {
    fill: white;
    width: 180px;
}

nav.open {
    z-index: 5 !important
}

;
</style>