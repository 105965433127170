document.addEventListener('DOMContentLoaded', function () {
	window.addEventListener('scroll', function () {
		if (window.scrollY !== 0) {
			document.getElementById('gotop').style.display = 'block';

			if (document.getElementById('nav'))
				document.getElementById('nav').classList.add('nav-color');
		} else {
			document.getElementById('gotop').style.display = 'none';

			if (document.getElementById('nav'))
				document.getElementById('nav').classList.remove('nav-color');
		}
	});
	document.getElementById('gotop').addEventListener('click', function () {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	});
	var gridItems = document.querySelectorAll('.swiper-slide');
	gridItems.forEach(function (item, index) {
		item.style.transitionDelay = 0.1 * (0 + index) + 's';

	});
});
