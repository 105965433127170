import { createRouter, createWebHistory } from "vue-router";
import { ElMessage } from "element-plus";
import axios from "axios";

function islogin(to, from, next) {
  if (!$cookies.get("token")) {
    ElMessage.error("請先登入以進行操作");
    next({ name: "Sign In" });
  } else {
    next();
  }
}

function isAdmin(to, from, next) {
  return axios
    .get("/api/auth_check/isAdmin", {
      headers: {
        'Authorization': `Bearer ` + $cookies.get('token')
      }
    }, {})
    .then((res) => {
      next();
    }).catch(function (error) {
      if (error.response) {
        ElMessage.error("Permission denied,please login");
        next("/manage/login");
      }
    });
}

function isDealer(to, from, next) {
  return axios
    .get("/api/auth_check/isDealer", {
      headers: {
        'Authorization': `Bearer ` + $cookies.get('token')
      }
    }, {})
    .then((res) => {
      next();
    }).catch(function (error) {
      if (error.response) {
        ElMessage.error("Permission denied,please login");
        next("/login");
      }
    });
}

const routes = [
  //main
  {
    path: "/:catchAll(.*)",
    name: "NotFoundall",
    redirect: { name: "HomeView" },
  },
  {
    path: "/",
    name: "HomeView",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/sitemap.xml",
    component: () => import("@/views/HomeView.vue"),
    beforeEnter(to, from, next) {
      window.location.href = "https://acc.bakerychu.com/api/sitemap.xml";
    }
  },
  {
    path: "/about",
    name: "AboutView",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "ContactView",
    component: () => import("@/views/ContactView.vue"),
  },
  {
    path: "/findus",
    name: "FindusView",
    component: () => import("@/views/FindusView.vue"),
  },
  {
    path: "/privacy",
    name: "PrivacyView",
    component: () => import("@/views/PrivacyView.vue"),
  },
  {
    path: "/terms",
    name: "TermsView",
    component: () => import("@/views/TermsView.vue"),
  },
  {
    path: "/product",
    name: "ProductListView",
    component: () => import("@/views/1_Product/ProductListView.vue"),
  },
  {
    path: "/product/:product_model",
    name: "ProductView",
    component: () => import("@/views/1_Product/ProductView.vue"),
  },
  // {
  //   path: "/product/model360/:product_model?",
  //   name: "Model360View",
  //   component: () => import("@/views/1_Product/Model360View.vue"),
  // },
  {
    path: "/news",
    name: "NewsListView",
    component: () => import("@/views/News/NewsListView.vue"),
  },
  {
    path: "/news/:id",
    name: "NewsView",
    component: () => import("@/views/News/NewsView.vue"),
  },
  // {
  //   path: "/download-center",
  //   name: "DownloadCenterListView",
  //   component: () => import("@/views/DownloadCenter/DownloadCenterListView.vue"),
  // },
  // {
  //   path: "/download/:type?/:id?",
  //   name: "DownloadCenterView",
  //   component: () => import("@/views/DownloadCenter/DownloadCenterView.vue"),
  // },

  //admin
  //main
  {
    path: "/manage/login",
    name: "AdminLoginView",
    component: () => import("@/views/AdminLoginView.vue"),
  },
  {
    path: "/manage/:type?/:uuid?/:view?",
    name: "ManageView",
    component: () => import("@/views/Admin/ManageView.vue"),
    beforeEnter: isAdmin,
  },

  //Dealer
  {
    path: "/login",
    name: "DealerLoginView",
    component: () => import("@/views/DealerLoginView.vue"),
  },
  {
    path: "/dealer/:catchAll(.*)",
    name: "NotFoundd",
    redirect: { name: "AccountView" },
  },
  {
    path: "/dealer",
    name: "AccountView",
    component: () => import("@/views/AccountView.vue"),
    beforeEnter: isDealer,
  },
  {
    path: "/dealer/parts_catalog",
    name: "WorkpieceListView",
    component: () => import("@/views/2_Workpiece/WorkpieceListView.vue"),
    beforeEnter: isDealer,
  },
  {
    path: "/dealer/parts_catalog/:id/part",
    name: "PartListView",
    component: () => import("@/views/3_Part/PartListView.vue"),
    beforeEnter: isDealer,
  },
  {
    path: "/dealer/order",
    name: "OrderView",
    component: () => import("@/views/Order/OrderView.vue"),
    beforeEnter: isDealer,
  },
  {
    path: "/dealer/order/upload_checkout",
    name: "OrderUploadCheckOutView",
    component: () => import("@/views/Order/OrderUploadCheckOutView.vue"),
    beforeEnter: isDealer,
  },
  {
    path: "/dealer/cart",
    name: "CartView",
    component: () => import("@/views/Order/CartView.vue"),
    beforeEnter: isDealer,
  },
  {
    path: "/dealer/cart/checkout",
    name: "CheckOutView",
    component: () => import("@/views/Order/CheckOutView.vue"),
    beforeEnter: isDealer,
  },
  {
    path: "/dealer/support",
    name: "SupportView",
    component: () => import("@/views/SupportView.vue"),
    beforeEnter: isDealer,
  },

];

const router = createRouter({
  mode: "hash",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    const name = to.name
    if (name != 'ManageView')
      return { top: 0 };
    else
      return;
  },
});

export default router;
