<template>
  <div>
    <!-- <div class="container">
    <div class="row"> -->
    <Navbar :key="key" v-if="finish" />

    <router-view @changefinish="changefinish" />
    <!-- </div>
</div> -->


    <!-- footer strat-->
    <ACCFooter v-if="$route.name != 'ManageView' && finish" />
    <div class="empty_footer" v-else></div>
    <!-- footer end-->
    <!-- gotop -->
    <div id="gotop" class="gotop bg-blue-dark">
      <div class="d-flex justify-content-center align-items-center h-100">
        <i class="fas fa-arrow-up"></i>
      </div>
    </div>
    <!-- gotop -->
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import ACCFooter from '@/components/Footer'
import '@/assets/js/custom';

export default {
  data() {
    return {
      key: 0,
      token: '',
      finish: true,
    }
  },
  components: {
    ACCFooter,
    Navbar,
  },
  methods: {
    changefinish() {
      this.finish = true
    }
  },
  watch: {
    '$route.name': {
      handler() {
        if (this.$route.name == 'HomeView')
          this.finish = false
        const white = ["AccountView", "WorkpieceListView", "PartListView", "OrderView", "CartView", "SupportView", "CheckOutView", "OrderUploadCheckOutView"];
        if (white.includes(this.$route.name))
          document.documentElement.classList.remove('dark');
        else
          document.documentElement.classList.add('dark');


        const sa_show = ["AboutView", "ContactView", "FindusView", "PrivacyView",
          "TermsView", "ProductListView", "NewsListView", "NewsView", "AdminLoginView", "DealerLoginView"];
        if (sa_show.includes(this.$route.name)) {
          document.documentElement.classList.add('space-area');
        }
        else {
          document.documentElement.classList.remove('space-area');
        }
        this.key++

      },
      immediate: true,
    },
  },

}
</script>

<!-- font-family: 'Josefin Sans', sans-serif;
font-family: 'Noto Sans TC', sans-serif;
font-family: 'Oswald', sans-serif; -->
<style>
@import "@/assets/css/mdb.free.css";
@import url("https://unpkg.com/swiper@7/swiper-bundle.min.css");

p span {
  white-space: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
div {
  word-break: keep-all
}

.empty_footer {
  height: 15vh;
}

.social-btn {
  justify-content: center;
  align-items: center;
}

:root {
  --bs-body-bg: #1A1A1A !important;
}

@media (min-width: 1200px) {
  .el-dialog {
    width: 70% !important;
  }

  .manage_dialog {
    padding: 0 10%;
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
  }

  .el-image-viewer__img {
    max-height: fit-content !important;
    width: 50vw;
  }

  .price {
    font-size: 18px;
  }

  .ql-video {
    width: 50%;
    height: 40vh;
  }

  .fixed_pirce_bar {
    width: 50% !important;
    margin-left: -25%;
  }
}

@media (max-width: 1200px) {
  .el-dialog {
    width: 95% !important;
  }

  .manage_dialog {
    padding: 0 5%;
    width: 100%;
    height: 70vh;
    overflow-y: scroll;
  }

  .el-image-viewer__img {
    max-height: fit-content !important;
    width: 90vw;
  }

  .price {
    font-size: 16px;
  }

  .fixed_pirce_bar {
    width: 90% !important;
    margin-left: -45%;
  }
}



.listimage {
  width: 40%;
}

span {
  white-space: nowrap
}

.el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section {
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none !important;
  cursor: pointer !important;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.shake {
  animation-name: shake;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom center;
}

@keyframes shake {

  0%,
  100% {
    transform: rotate(0deg);
  }

  10%,
  90% {
    transform: rotate(-3deg);
    /* 調整抖動角度 */
  }

  20%,
  80% {
    transform: rotate(3deg);
    /* 調整抖動角度 */
  }

  30%,
  70% {
    transform: rotate(-3deg);
    /* 調整抖動角度 */
  }

  40%,
  60% {
    transform: rotate(3deg);
    /* 調整抖動角度 */
  }

  50% {
    transform: rotate(-3deg);
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: rgb(219, 219, 219);
}

.spinner::before {
  background: rgba(255, 255, 255, 0) !important;
}

.swiper-wrapper {
  align-items: center;
}

.tableAuto.el-table .cell {
  white-space: nowrap;
}

.el-pagination {
  justify-content: center;
  overflow-x: scroll;
}

.el-upload-list--picture .el-upload-list__item-thumbnail {
  width: 50% !important;
  height: 100% !important;
}

.el-upload-list--picture .el-upload-list__item {
  justify-content: center;
  flex-direction: column;
}

.el-select-tags-wrapper {
  display: flex !important;
  flex-wrap: wrap;
}


.fixed_pirce_bar {
  border-radius: 5vh;
  height: 10vh;
  background-color: #f5f5f5;
  position: fixed;
  bottom: 5%;
  left: 50%;
  z-index: 20;
}

.dark a {
  color: #fff !important;
}

.filename a {
  color: #000000 !important;
}

.navbar-collapse {
  z-index: 30;
}

/* 
/* table */
.rwd-table {
  background: #fff;
  overflow: hidden;

}


.rwd-table {
  min-width: 100%;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:before {
  content: attr(data-th) " : ";
  font-weight: bold;
  width: 6.5em;
  display: inline-block;
}


.rwd-table th,
.rwd-table td:before {
  font-weight: bold;
}


@media (max-width: 1200px) {
  .rwd-table tr:nth-of-type(2n) {
    background: #eee;
  }

  .rwd-table th,
  .rwd-table td {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .change {
    width: 170px;
  }

  .rwd-table td:before {
    display: none;
  }

  .rwd-table th,
  .rwd-table td {
    display: table-cell;
  }

  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }

  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }

}
</style>
