<template>
    <div id="footer">
        <div class="footer-bg-color container-fulid px-3 px-lg-5">
            <!-- Grid container -->
            <div class="d-flex align-items-center justify-content-center justify-content-lg-between py-4 py-lg-5">
                <div class="logo" @click="$router.push({ name: 'HomeView' });" style="cursor: pointer;">
                    <img src="@/assets/img/logo2.svg">
                </div>
                <div class="nav d-none d-lg-flex justify-content-between small">
                    <a href="https://www.youtube.com/@AccessMotorGlobal" class="me-4"><i
                            class="fab fa-youtube me-2"></i>Youtube</a>
                    <a href="https://www.linkedin.com/company/access-motor-co/" class="me-4"><i
                            class="fab fa-linkedin me-2"></i>Linkedin</a>
                    <a @click="$router.push({ name: 'TermsView' });" class="me-4">Terms & Conditions</a>
                    <a @click="$router.push({ name: 'PrivacyView' });">Privacy Policy</a>
                </div>
            </div>
            <div
                class="text-white-50 small d-flex flex-column flex-lg-row align-items-center justify-content-between pb-3">
                <div class="small text-center d-none d-lg-block">
                    <span class="me-3">TEL +886 6-5955775</span>
                    <span class="me-3">FAX +886 6-5953393</span>
                    <span>No.288, Sec.1, Guanxin Rd., Guanmiao Dist., Tainan City 718, Taiwan (R.O.C.)</span>
                </div>
                <div class="small">© 2023 Copyright accessmotor</div>
            </div>
            <!-- Grid container -->
            <!-- Copyright -->
            <!--<div class="row g-0 px-xl-5">
		
	</div>-->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style>
#footer a {
    color: white;
}
</style>