import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";

import router from "./router";

import ElementPlus from "element-plus";
import { ElMessage } from 'element-plus';
// import locale from "element-plus/dist/locale/zh-tw.mjs";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css";
import * as Icons from '@element-plus/icons-vue' // 引入所有图标，并命名为 Icons
import 'element-plus/theme-chalk/dark/css-vars.css'

// import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"

import axios from "axios";
import VueAxios from "vue-axios";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import timeago from "vue-timeago3";
import { zhTW } from "date-fns/locale";
const timeagoOptions = {
    locale: zhTW,
};

import vue3dLoader from "vue-3d-loader";

import UUID from "vue3-uuid";

import JsonViewer from 'vue-json-viewer'

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// const loadGoogleMapsAPI = () => {
//     return new Promise((resolve) => {
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDQlXhJw1Hg7-eziZC5yctZp32nKQ_KLmA&libraries=places,geometry&language=en&callback=Function.prototype`;
//         script.onload = resolve;
//         document.head.appendChild(script);
//     });
// };

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
    duration: 1000,
});
import download from 'downloadjs'

import "@fancyapps/ui/dist/fancybox/fancybox.css";

const app = createApp(App);

app.component("QuillEditor", QuillEditor);

app.use(ElementPlus
    // , {locale: { locale },}
);
app.config.globalProperties.$ElMessage = ElMessage;
app.config.globalProperties.window = window;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$app_name = "ACC";
app.config.globalProperties.$global_url = "https://accessmotorglobal.com/api/";

function formatSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
};
app.config.globalProperties.$formatSize = formatSize;

for (let i in Icons) {
    app.component(i, Icons[i])
}

app.component('Swiper', Swiper);
app.component('SwiperSlide', SwiperSlide);

app
    .use(store)
    .use(VueAxios, axios)
    .use(router)
    .use(timeago, timeagoOptions)
    .use(require("vue-cookies"))
    .use(vue3dLoader)
    .use(UUID)
    .use(JsonViewer);

app.mount('#app');

// loadGoogleMapsAPI().then(() => {
//     app.mount('#app');
// });
//ncu --upgrade
//npm install --legacy-peer-deps 衝突時用
